<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible"
             :title="model.id ? $t('common.edit') : $t('common.add')" width="1300px" top="3vh" @close="$reset('form')">
    <el-form ref="form" :label-width="$l('160px', '130px')" :model="model">
      <div>
        <el-divider content-position="left">{{$t("elevatorNetApply.companyInfo")}}</el-divider>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.useUnit')" prop="useUnit" :rules="$rule.notNull">
            <el-autocomplete v-model="model.useUnit" class="inline-input" :fetch-suggestions="useUnitQuerySearch"
                             :placeholder="$t('common.pleaseEnter')" :trigger-on-focus="false" style="width: 100%"
                             @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.useUnitDistrict')" prop="useUnitDistrictId">
            <vm-district-select v-model="model.useUnitDistrictId" :placeholder="$t('common.pleaseSelect')">
            </vm-district-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.useUnitAddress')" prop="useUnitAddress">
            <el-input v-model="model.useUnitAddress" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.installAddress')" prop="installAddress">
            <el-input v-model="model.installAddress" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.manufactured')" prop="createComName" :rules="$rule.notNull">
            <el-autocomplete v-model="model.createComName" class="inline-input" :fetch-suggestions="companyQuerySearch"
                             :placeholder="$t('common.pleaseEnter')" :trigger-on-focus="false" style="width: 100%"
                             @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.createCreditCode')" prop="createCreditCode">
            <el-input v-model.trim="model.createCreditCode" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.maintComName')" prop="maintComName">
            <el-autocomplete v-model="model.maintComName" class="inline-input" :fetch-suggestions="companyQuerySearch"
                             :placeholder="$t('common.pleaseEnter')" :trigger-on-focus="false" style="width: 100%"
                             @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.maintCreditCode')" prop="maintCreditCode">
            <el-input v-model.trim="model.maintCreditCode" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.maintEmpName')" prop="maintEmpName">
            <el-input v-model.trim="model.maintEmpName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.maintEmpPhone')" prop="maintEmpPhone">
            <el-input v-model.trim="model.maintEmpPhone" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.salesman')" prop="salesman">
            <el-input v-model.trim="model.salesman" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('elevator.salesmanTel')" prop="salesmanTel">
            <el-input v-model.trim="model.salesmanTel" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.propertyCom')" prop="propertyCom">
            <el-autocomplete v-model="model.propertyCom" class="inline-input" :fetch-suggestions="companyQuerySearch"
                             :placeholder="$t('common.pleaseEnter')" :trigger-on-focus="false" style="width: 100%"
                             @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.proName')" prop="projectName">
            <el-input v-model.trim="model.projectName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevator.agent')" prop="agentName">
            <el-autocomplete v-model="model.agentName" class="inline-input" :fetch-suggestions="companyQuerySearch"
                             :placeholder="$t('common.pleaseEnter')" :trigger-on-focus="false" style="width: 100%"
                             @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <div></div>
        </div>
      </div>
      <div>
        <div style="width: 68%;float: left;padding-right: 2%">
          <el-divider content-position="left">{{$t("elevatorNetApply.elevatorInfo")}}</el-divider>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.elevatorName')" prop="elevatorName" :rules="$rule.notNull">
              <el-input v-model="model.elevatorName" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item  :label="$t('elevator.elevatorType')" prop="categoryCode" :rules="$rule.notNull">
              <el-select
                v-model="model.categoryCode"
                :placeholder="$t('elevator.elevatorType')"
                style="width: 100%">
                <el-option
                  v-for="item in categoryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.regCode')" prop="regCode">
              <el-input v-model.trim="model.regCode" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.factoryNumber')" prop="factoryNumber">
              <el-input v-model="model.factoryNumber" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.brand')" prop="brand">
              <el-input v-model.trim="model.brand" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.elevatorModel')" prop="elevatorModel">
              <el-input v-model="model.elevatorModel" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate" v-if=" !(model.categoryCode == '3310' ||  model.categoryCode == '3320') ">
            <el-form-item :label="$t('elevatorNetApply.speed')" prop="speed" :rules="$rule.notNull">
              <el-input v-model.trim="model.speed" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.load')" prop="load">
              <el-input v-model="model.load" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>

          <div class="vm-separate" v-if="model.categoryCode == '3310' ||  model.categoryCode == '3320' ">
            <el-form-item :label="$t('elevator.ratedSpeedOfEscalator')" prop="speed" :rules="$rule.notNull">
              <el-input
                v-model="model.speed"
                type="number"
                :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevator.angleOfEscalator')" prop="angle">
              <el-input v-model="model.angle" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate" v-if="model.categoryCode == '3310' ||  model.categoryCode == '3320' ">
            <el-form-item :label="$t('elevator.riseOfEscalator')" prop="rise">
              <el-input v-model="model.rise" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <div></div>
          </div>
          <div class="vm-separate" v-if="model.categoryCode == '3310' ||  model.categoryCode == '3320' ">
            <el-form-item :label="$t('elevator.widthOfEscalator')" prop="width">
              <el-input v-model="model.width" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevator.lengthOfEscalator')" prop="length">
              <el-input v-model="model.length" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>


          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.ladderNo')" prop="ladderNo">
              <el-input v-model="model.ladderNo" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.buildingNo')" prop="buildingNo">
              <el-input v-model.trim="model.buildingNo" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.exFactoryDate')" prop="factoryDate">
              <el-date-picker v-model="model.factoryDate" type="date" :placeholder="$t('common.pleaseSelect')"
                              style="width: 100%" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.installationDate')" prop="installDate">
              <el-date-picker v-model="model.installDate" type="date" :placeholder="$t('common.pleaseSelect')"
                              style="width: 100%" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevator.whereUsed')" prop="applyPlaceId">
              <el-select v-model="model.applyPlaceId" :placeholder="$t('elevator.whereUsed')" style="width: 100%">
                <el-option v-for="item in placeList" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <div></div>
          </div>
          <div v-if="false" class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.controlModel')" prop="controlModel">
              <el-input v-model="model.controlModel" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <div></div>
          </div>
          <el-divider content-position="left">{{$t("elevator.iot")}}</el-divider>
          <div class="vm-separate">
            <el-form-item :label="$t('elevator.gateWayType')" prop="gateWayType" :rules="$rule.notNull">
              <el-select v-model="model.gateWayType" :placeholder="$t('elevator.gateWayType')" style="width: 100%">
                <el-option v-for="item in gateWayTypeOptions" :key="item.value" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.sim')" prop="sim">
              <el-input v-model.trim="model.sim" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item v-if="false" :label="$t('elevatorNetApply.dtuModel')" prop="dtuModel">
              <el-input v-model="model.dtuModel" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.dtuCode')" prop="dtuCode" :rules="$rule.notNull">
              <el-input v-model="model.dtuCode" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.nodeCode')" prop="nodeCode">
              <el-input v-model="model.nodeCode" :placeholder="$t('common.pleaseEnter')" disabled></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.videoChannelId')" prop="videoChannelId">
              <el-input v-model="model.videoChannelId" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <div></div>
          </div>
        </div>
        <div style="width: 30%;float: left;">
          <div v-if="!(model.categoryCode == '3310' ||  model.categoryCode == '3320')">
            <el-divider content-position="left">{{$t("elevatorNetApply.floorMappings")}}</el-divider>
            <el-table ref="vmTable" height="360px" border :data="model.floorMappings">
              <el-table-column type="index" width="50">
              </el-table-column>
              <table-column :tooltip="false" prop="floorLocation" :label="$t('elevatorNetApply.floorLocation')" align="center"
                               :width="120">
                <template #default="scope">
                  <el-input v-model="scope.row.floorLocation"></el-input>
                </template>
              </table-column>
              <table-column prop="code" :label="$t('elevatorNetApply.displayCode')" align="center">
                <template #default="scope">
                  <el-input v-model="scope.row.code"></el-input>
                </template>
              </table-column>
              <table-column :label="$t('common.operate')" align="center" width="120px" :tooltip="false">
                <template #default="scope">
                  <el-button type="danger" @click="model.floorMappings.splice(scope.$index, 1);">
                    {{$t("common.delete")}}
                  </el-button>
                </template>
              </table-column>
            </el-table>
            <div style="margin-bottom: 10px">
              <el-button type="text" @click="addRow">
                + {{$t("elevatorNetApply.addFloorMappings")}}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button v-if="!model.elevatorId && model.id > 0" type="success" :loading="loading" @click="create">{{
          $t("common.generation")
        }}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VmDistrictSelect from "../../components/VmDistrictSelect";

  const moduleName_productType = "elevatorProductTypes";

  export default {
    components: {VmDistrictSelect},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        loading: false,
        activeTab: "first",
        useUnitList: [],
        companyList: [],
        productTypeList: [],
        categoryList: [],
        placeList: [],
        model: {
          id: 0,
          useUnit: "",
          useUnitAddress: "",
          createComName: "",
          createCreditCode: "",
          maintComName: "",
          maintCreditCode: "",
          maintEmpName: "",
          maintEmpPhone: "",
          salesman: "",
          salesmanTel: "",
          installAddress: "",
          projectName: "",
          propertyCom: "",
          agentName: "",
          dtuCode: "",
          nodeCode: "01",
          buildingNo: "",
          ladderNo: "",
          categoryCode: "",
          brand: "",
          elevatorModel: "",
          gateWayType: 1,
          load: "",
          speed: "",
          rise: "",
          angle: "",
          width: "",
          length: "",
          sim: "",
          dtuModel: "",
          controlModel: "",
          elevatorId: "",
          regCode: "",
          factoryNumber: "",
          elevatorName: "",
          factoryDate: "",
          installDate: "",
          videoChannelId: "",
          floorMappings: [],
          useUnitDistrictId: 0,
          isCreate: false,
        },
        gateWayTypeOptions: [
          {value: 1, label: this.$t("elevator.gateWayType1")},
          {value: 2, label: this.$t("elevator.gateWayType2")},
          {value: 3, label: this.$t("elevator.gateWayType3")},
        ],
      };
    },
    methods: {
      useUnitQuerySearch(queryString, cb) {
        if (this.useUnitList && this.useUnitList.length) {
          cb(this.filterQueryString(this.useUnitList, queryString));
        } else {
          this.$http.get("companies/useUnit")
            .then(res => {
              this.useUnitList = res.data.map(item => {
                return {
                  value: item.name,
                };
              });
              cb(this.filterQueryString(this.useUnitList, queryString));
            });
        }
      },
      getPlaceList() {
        this.$api.getList("dicts/ApplyPlace").then(response => {
          this.placeList = [];
          for (let place of response.data) {
            let item = {value: place.code, label: place.name};
            this.placeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      companyQuerySearch(queryString, cb) {
        if (this.companyList && this.companyList.length) {
          cb(this.filterQueryString(this.companyList, queryString));
        } else {
          this.$http.get("companies/list")
            .then(res => {
              this.companyList = res.data.map(item => {
                return {
                  value: item.name,
                };
              });
              cb(this.filterQueryString(this.companyList, queryString));
            });
        }
      },
      filterQueryString(list, queryString) {
        return list.filter(item => {
          return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
        });
      },
      handleSelect(item) {
      },
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getAll();

        if (this.model.id) {
          this.getData();
        }
        // this.model.applyPlaceId = 13;
      },
      getData() {
        this.$http
          .get("elevatorNetApply/" + this.model.id)
          .then(({data}) => {
            //this.$assign(this.model, data);
            this.model = Object.assign(this.model, data);
          });
      },
      addRow() {
        this.model.floorMappings.push({
          code: "",
          floorLocation: "",
        });
      },
      create() {
        this.$confirm(this.$t("common.generateElevatorRecords") + " ( " + this.$t("elevatorNetApply.dtuCode") + " " + this.model.dtuCode + " )," + this.$t("common.isGenerate") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.loading = true;
          this.$http.save("elevatorNetApply", this.model).then(() => {
          });

          this.$http.post(`elevatorNetApply/create/${this.model.id}`).then(() => {
            this.loading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("elevatorNetApply.generateSuccess"));
          }).catch(() => {
            this.loading = false;
          });
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {

          if (!(this.model.categoryCode === "3310" || this.model.categoryCode === "3320")) {
            if (!this.model.floorMappings.length) {
              this.$message.error(this.$t("common.tip.completeFloorInformation"));
              return;
            }
          }

          if (!this.model.factoryNumber && !this.model.regCode) {
            this.$message.error(this.$t("common.tip.optional"));
            return;
          }
          if (this.model.salesman) {
            if (!this.model.salesmanTel) {
              this.$message.error("业务员不为空时，请填写业务员手机");
              return;
            }
          }
          if (valid) {
            this.submitLoading = true;
            this.$http.save("elevatorNetApply", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
      getAll() {
        this.$axios.all([this.getProductTypeList(), this.getPlaceList()]).then(this.$axios.spread(function(acct, perms) {
        }));
      },
      getProductTypeList() {
        this.$api.getList(moduleName_productType).then(response => {
          this.productTypeList = [];
          this.categoryList = [];
          for (let productType of response.data) {
            let item2 = "";
            if (this.$i18n.isCn) {
              item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeName};
            } else {
              item2 = {value: productType.elevatorProductTypeNO, label: productType.elevatorProductTypeNameEn};
            }
            this.categoryList.push(item2);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
